.table_scroll_main {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
}

.css_sd_centre {
  text-align: center !important;
}

.table_scroll {
  overflow: auto;
  resize: vertical;
  margin:auto;
  box-shadow: 0 0.25rem 1.125rem rgba(75,70,92,.1);
  margin: 0 auto;
  position: relative;
  height: calc(100vh - 125px);
  min-height: 400px;
  scrollbar-width: 10px;
  scrollbar-height: 10px;
  scrollbar-color: rgba(93,89,108,0.3);
}
.table_scroll::-webkit-scrollbar-thumb {
    background-color: rgba(93,89,108,0.3);
}
.css_table {
  display: table;
  position: relative;
  width:100%;
}
.css_tr {
  display: table-row;
  position: relative;
}
.css_tr:hover {
  z-index: 200;
  -webkit-box-shadow: 0px 0px 10px rgb(115 103 240 / 45%);
          box-shadow: 0px 0px 10px rgb(115 103 240 / 45%);
}
.css_tr:nth-child(even) .css_td {
/*  background: #f5f5f5;*/
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.css_tr:nth-child(odd) .css_td {
  background: #fff;
}
.css_td,
.css_th,
.css_sd{
  display: table-cell;
  text-align: center;
  padding: 0.55rem 1.25rem;
  font-weight: normal;
}
.css_sd {
  z-index: 22;
}

.css_sd + .css_sd {
  z-index: 10;
  background-color: #fff;
}
.css_thead {
  display: table-header-group;
  font-weight: bold;
  position: relative;
  z-index:300;
}
  .css_thead .css_tr:hover {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
.css_tfoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.css_tbody {
  display: table-row-group;
}
/* .css_th, .css_sd{
  position: sticky;
  background: #eee;
} */
.css_th {
  position: sticky;
  background: #eee;
}
.css_th:nth-child(2) {
  /* position: sticky; */
  /* left: 45px; */
  z-index: 2;
  border-right: 1px solid #cdcdcd;
}
.css_th{
  vertical-align: bottom;
  white-space: nowrap;
  top: 0;
  color: #5d596c;
  font-size: .8125rem;
  letter-spacing: 1px;
  padding-bottom: 0.88rem;
  padding-top: 0.88rem;
  text-transform: uppercase;
  border-bottom: 1px solid #cdcdcd;
  font-size: 14px;
}
.css_sd{
  left: 0;
  text-align: left;
}
/* .css_thead div.css_th:first-child,
.css_tfoot div.css_th:first-child{
  left:0;
  z-index:1;
} */
.css_tfoot .css_th{
  bottom:0
}
.css_sd + .css_sd {
  left: 45px;
  border-right: 1px solid #cdcdcd;
}
.css_th_center .icon-tabler {
  margin-top: 22px;
}
.css_td {
  text-align: left;
  font-size: 16px;
  line-height: 120%;
}
.css_th_str {
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.css_th_str svg {
  cursor: pointer;
}
.css_sd_nowrap {
  white-space: nowrap;
}
.table_scroll_str {
  position: absolute;
  height: calc(100vh - 120px);
  top: 0;
  display: flex;
  z-index: 50;
  cursor: pointer;
  pointer-events: none;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.table_scroll_str img {
  display: block;
  width: 60px;
  pointer-events: all;
}
.table_scroll_str_l {
  left: 0;
}
.table_scroll_str_r {
  right: 10px;
  margin-right: 0;
  will-change: transform;
}
.table_scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.table_scroll::-webkit-scrollbar-track {
  background: #eee;
}
.table_scroll::-webkit-scrollbar-thumb {
  background-color: rgba(93,89,108,0.3);
  border-radius: 0px;
}

.table_time {
  overflow: hidden !important;
}

.table_time .table th, .table_time .table td {
  padding-left: 5px !important;
  padding-right: 5px !important;
}


@media screen and (max-width: 860px) {
  .table_scroll_str {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .css_th {
    font-size: 12px;
    padding: 10px;
  }
  .css_td, .css_th, .css_sd {
    font-size: 12px;
    padding: 10px;
  }
  .css_td .form-select {
    width: 200px !important;
    font-size: 12px;
  }
  .css_th:nth-child(2), .css_sd + .css_sd {
    /* left: 40px; */
  }
}

@media screen and (max-width: 380px) {
  .css_th {
    font-size: 10px;
    padding: 5px;
  }
  .css_td .form-select {
    padding-left: 5px;
    padding-right: 20px;
    background-position: right center;
  }
  .css_td, .css_th, .css_sd {
    font-size: 10px;
    padding: 5px;
  }
  .css_td .form-select {
    width: 150px !important;
    font-size: 10px;
  }
  .css_sd + .css_sd, .css_th:nth-child(2) {
    left: 30px;
  }
}